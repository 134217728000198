import React, { useState, useEffect } from 'react';

function AffirmationTimer({ affirmation }) {
  const [timeLeft, setTimeLeft] = useState(300);  // 5 minutes = 300 seconds

  useEffect(() => {
    if (timeLeft === 0) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div>
      <h1>Your Affirmation</h1>
      <p>{affirmation}</p>
      <h2>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</h2>
      <p>Focus on your affirmation during this time.</p>
    </div>
  );
}

export default AffirmationTimer;
