// paymentpage.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import '../style.css';  // This moves one folder up from `components/` to `src/`

const stripePromise = loadStripe('your-public-stripe-key');

function PaymentPage() {
  const [plan, setPlan] = useState('basic'); // Default plan is 'basic'

  const handlePayment = async () => {
    const stripe = await stripePromise;

    // Define the price ID based on the selected plan
    const priceId = plan === 'premium' ? 'premium_price_ID' : 'basic_price_ID';

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: 'subscription',
      successUrl: 'https://your-success-url.com',
      cancelUrl: 'https://your-cancel-url.com',
    });

    if (error) {
      console.error('Payment failed:', error);
    }
  };

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };

  return (
    <div className="payment-page">
      <h2>Manage Your Subscription</h2>

      {/* Plan selection for upgrade/downgrade */}
      <div className="plan-selection">
        <h3>Select Your Plan</h3>
        <label>
          <input
            type="radio"
            name="plan"
            value="basic"
            checked={plan === 'basic'}
            onChange={handlePlanChange}
          />
          Basic Plan - $10/month
        </label>
        <label>
          <input
            type="radio"
            name="plan"
            value="premium"
            checked={plan === 'premium'}
            onChange={handlePlanChange}
          />
          Premium Plan - $20/month
        </label>
      </div>

      {/* Subscribe or upgrade/downgrade */}
      <button onClick={handlePayment} className="cta-button">
        {plan === 'premium' ? 'Upgrade to Premium' : 'Subscribe Now'}
      </button>

      {/* Cancel Subscription */}
      <div className="cancel-subscription">
        <h3>Cancel Your Subscription</h3>
        <button
          onClick={() => window.location.href = 'https://your-cancel-subscription-url.com'}
          className="cancel-button"
        >
          Cancel Subscription
        </button>
      </div>
    </div>
  );
}

export default PaymentPage;
