import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomePage from './components/WelcomePage';
import FeelingAssessment from './components/FeelingAssessment';
import AffirmationVideo from './components/AffirmationVideo';
import AffirmationTimer from './components/AffirmationTimer';
import PaymentPage from './components/PaymentPage';
import './style.css'; // Import custom CSS
import logo from 'https://flowly.s3.amazonaws.com/flowly_logo.png'; // Import logo

function App() {
  return (
    <Router>
      <div className="App">
        {/* App Header with Logo */}
        <header>
          <img src={logo} className="logo" alt="Flowly App Logo" />
        </header>

        {/* Application Routes */}
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/assessment" element={<FeelingAssessment />} />
          <Route path="/affirmations" element={<AffirmationVideo />} />
          <Route path="/create-affirmation" element={<AffirmationTimer />} />
          <Route path="/subscribe" element={<PaymentPage />} />

          {/* Fallback route for 404 - Page Not Found */}
          <Route path="*" element={<h2>404 - Page Not Found</h2>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
