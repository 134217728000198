/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:af7c09e4-f8ca-4b4d-b2e8-43a773b57b08",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nybeXb1F2",
    "aws_user_pools_web_client_id": "783h47vkmfir30isajrc6j99td",
    "oauth": {
        "domain": "flowlyapp-login-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.swiftalchemy.com/profile-setup/,https://app.swiftalchemy.com/login/,https://app.swiftalchemy.com/morning-assessment/,https://app.swiftalchemy.com/afternoon-assessment/,https://app.swiftalchemy.com/community/",
        "redirectSignOut": "https://app.swiftalchemy.com/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
