import React, { useState } from 'react';


function FeelingAssessment() {
  const [feeling, setFeeling] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("User feeling:", feeling);
    // You can replace this with a POST request to save the user's response
    window.location.href = '/affirmations';  // Navigate to the affirmations page
  };

  return (
    <div>
      <h2>How are you feeling right now?</h2>
      <form onSubmit={handleSubmit}>
        <select value={feeling} onChange={(e) => setFeeling(e.target.value)}>
          <option value="">Select how you're feeling</option>
          <option value="anger">Angry</option>
          <option value="bliss">Scared</option>
          <option value="concerned">Scared</option>
          <option value="enthusiasm">Scared</option>
          <option value="fear">Frustrated</option>
          <option value="frustration">Frustrated</option>
          <option value="happiness">Happy</option>
          <option value="harmony">Scared</option>
          <option value="joy">Joyful</option>
          <option value="love">Scared</option>
          <option value="peaceful">Scared</option>
          <option value="regret">Scared</option>
          <option value="scared">Scared</option>
          <option value="worried">Scared</option>
        
          {/* Add more emotional states if needed */}
        </select>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default FeelingAssessment;
