import React from 'react';

function WelcomePage() {
  return (
    <div>
      <h1>Welcome to Flowly</h1>
      <video controls>
        <source src="https://flowly-videos.s3.amazonaws.com/flowly/welcome.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button className="arrow-button" onClick={() => window.location.href='/assessment'}>
        --> 
      </button>
    </div>
  );
}

export default WelcomePage;

