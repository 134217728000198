import React from 'react';

function AffirmationVideo() {
  return (
    <div>
      <h1>Learn About Affirmations</h1>
      <video controls>
        <source src="https://flowly-videos.s3.amazonaws.com/flowly/affirmations.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button className="arrow-button" onClick={() => window.location.href='/create-affirmation'}>
        --> 
      </button>
    </div>
  );
}

export default AffirmationVideo;
